import { Box, Text } from '@wix/design-system';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from '../../translationsKeys';
import { AppContextProvider } from '../AppLoader';
import { DialogContainerProps, DialogFormContainer } from '../DialogContainer';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { PoliciesContainer } from '../PoliciesContainer';
import {
  SocialAuth,
  SocialAuthIconsWithApple,
  SocialAuthIconsWithoutApple,
  SocialAuthWithoutApple,
} from '../SocialAuth/SocialAuth';
import { ThemedText } from '../ThemedComponents';
import s from './AuthPage.scss';

export const AuthPageContextProvider = createContext<{
  store?: AuthStore;
}>({});
interface AuthPageProps {
  container: DialogContainerProps;
}

const _AuthPage: React.FC<AuthPageProps> = observer((props) => {
  return (
    <DialogFormContainer {...props.container} formClasses={[s.form]}>
      {props.children}
    </DialogFormContainer>
  );
});

export const Header: React.FC<{
  dataHooks?: { [key: string]: string };
}> = ({ dataHooks, children }) => {
  const { store } = useContext(AuthPageContextProvider);
  const {
    rootStore: {
      displayStore: { preset, isVerticalLayout, isMobile },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <DialogHeader dataHook={dataHooks?.header}>
      <DialogHeader.Title
        flow="authPage"
        className={classNames(s.title, {
          [s.vertical]: isVerticalLayout,
          [s.mobile]: isMobile,
        })}
        dataHook={dataHooks?.title}
      >
        {store?.titleKey &&
          t(store?.titleKey, { brand: t(preset?.nameKey ?? '') })}
      </DialogHeader.Title>
      {store?.presetSubtitleKey && (
        <DialogHeader.SubTitleSmall
          className={classNames(s.subtitle, { [s.vertical]: isVerticalLayout })}
          dataHook={dataHooks?.presetSubtitle}
        >
          {t(store?.presetSubtitleKey)}
        </DialogHeader.SubTitleSmall>
      )}
      {store?.subtitleKey && (
        <DialogHeader.SubTitleSmall
          className={classNames(s.subtitle, { [s.vertical]: isVerticalLayout })}
          dataHook={dataHooks?.subtitle}
          maxWidth="390px"
        >
          {t(store?.subtitleKey, { brand: t(preset?.nameKey ?? '') })}
        </DialogHeader.SubTitleSmall>
      )}
      {children ?? <></>}
    </DialogHeader>
  );
};

export const Body: React.FC<{ dividerText?: string }> = observer(
  ({ children, dividerText }) => {
    const {
      rootStore: {
        displayStore: { authFormWidth, direction, isMobile, sideBySideForm },
      },
    } = useContext(AppContextProvider);
    return (
      <Box direction={direction} width="100%" maxWidth={authFormWidth}>
        {children}
        <AuthDivider
          text={dividerText}
          className={classNames({ [s.horizontal]: !isMobile })}
        />
        <SocialAuth width={sideBySideForm.width} />
      </Box>
    );
  }
);

const MobileBody: React.FC<{ dividerText?: string }> = observer(
  ({ children }) => {
    const {
      rootStore: {
        socialAuthStore,
        displayStore: { sideBySideForm, isMobile },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <>
        {children}
        <Box
          gap={2}
          direction="horizontal"
          align="space-between"
          width={sideBySideForm.width}
          marginTop={3}
        >
          {!isMobile ||
            (!socialAuthStore.isUnsupportedAgentForSocialAuth && (
              <Box direction="vertical" verticalAlign="middle" marginBottom={3}>
                <Text>{t('emailStep.or.login.with')}</Text>
              </Box>
            ))}
          <SocialAuthIconsWithoutApple />
        </Box>
      </>
    );
  }
);

export const BodyWithSocialButtons: React.FC<{
  dividerText?: string;
}> = observer(({ children, dividerText }) => {
  const {
    rootStore: {
      displayStore: { authFormWidth, direction, isMobile, sideBySideForm },
    },
  } = useContext(AppContextProvider);
  return (
    <Box
      direction={direction}
      width="100%"
      maxWidth={authFormWidth}
      height={sideBySideForm.height('high')}
    >
      {isMobile ? (
        <>
          <Box marginBottom={4}>
            <SocialAuthWithoutApple width={sideBySideForm.width} />
          </Box>
          <AuthDivider text={dividerText} />
          {children}
        </>
      ) : (
        <>
          {children}
          <AuthDivider text={dividerText} className={s.horizontal} />
          <SocialAuthWithoutApple width={sideBySideForm.width} />
        </>
      )}
    </Box>
  );
});

export const BodyReverse: React.FC<{ dividerText?: string }> = observer(
  ({ children, dividerText }) => {
    const {
      rootStore: {
        displayStore: { authFormWidth },
      },
    } = useContext(AppContextProvider);
    return (
      <Box direction="vertical" width="100%" maxWidth={authFormWidth}>
        <SocialAuthIconsWithApple />
        <AuthDivider text={dividerText} />
        {children}
      </Box>
    );
  }
);

export const VerticalBody: React.FC<{
  socialFirst?: boolean;
  dividerText?: string;
}> = observer(({ children, socialFirst = true, dividerText }) => {
  const {
    rootStore: {
      displayStore: { authFormWidth },
    },
  } = useContext(AppContextProvider);
  if (socialFirst) {
    return (
      <Box
        direction="vertical"
        align="center"
        width="100%"
        maxWidth={authFormWidth}
      >
        <SocialAuth alignItems="center" />
        <AuthDivider text={dividerText} />
        {children}
      </Box>
    );
  }
  return (
    <Box
      direction="vertical"
      align="center"
      width="100%"
      maxWidth={authFormWidth}
    >
      {children}
      <AuthDivider text={dividerText} />
      <SocialAuth alignItems="center" />
    </Box>
  );
});

export const Policies: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <PoliciesContainer className={className}>{children}</PoliciesContainer>
  );
};

export const AuthDivider: React.FC<{ text?: string; className?: string }> = ({
  text,
  className,
}) => {
  const { t } = useTranslation();
  const {
    rootStore: {
      displayStore: { isVerticalLayout },
    },
  } = useContext(AppContextProvider);
  return (
    <div
      className={classNames(s.dividerContainer, className, {
        [s.vertical]: isVerticalLayout,
      })}
    >
      <div className={s.line} />
      <ThemedText size="small" weight="thin">
        <div className={s.orText}>{text ?? t(keys['emailStep.divider'])}</div>
      </ThemedText>
      <div className={s.line} />
    </div>
  );
};

export const BodyContainer: React.FC<{
  className?: string;
  width?: string;
}> = ({ className, width, children }) => {
  const {
    rootStore: {
      displayStore: { isVerticalLayout, preset, isMobile },
    },
  } = useContext(AppContextProvider);
  const containerWidth = useMemo(() => {
    if (width) {
      return width;
    }
    return isMobile ? '100%' : 'auto';
  }, [isMobile, width]);
  const containerStyle = isMobile
    ? preset.container?.mobile
    : preset.container?.desktop;
  if (isVerticalLayout) {
    return (
      <Box
        direction="vertical"
        align="center"
        padding={containerStyle?.padding}
        backgroundColor="#fff"
        width={containerWidth}
        borderRadius={containerStyle?.borderRadius}
        boxShadow={containerStyle?.boxShadow}
        className={classNames(
          { [s.vertical]: isVerticalLayout },
          s.bodyContainer,
          className
        )}
      >
        {children}
      </Box>
    );
  }
  return <>{children}</>;
};

export const AuthPage = Object.assign(_AuthPage, {
  Header,
  Body,
  BodyReverse,
  MobileBody,
  BodyWithSocialButtons,
  VerticalBody,
  Policies,
});
