import * as React from 'react';
import { ApprovalChallengeStatus as OriginalApprovalStatus } from '@wix/ambassador-identity-v1-verification/types';


export enum Steps {
  DeviceSelectionStep,
  ConfirmationStep,
  BackupMethodStep,
  SuccessfulStep,
  DownloadAppIntroStep,
  DownloadAppQrCodeStep,
}

interface StepDefinition {
  title?: string;
  subtitle?: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => Promise<void>;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  sideActions?: React.ReactNode;
  currentStep?: React.ReactNode;
  modalHeight?: number;
  modalWidth?: number;
  modalMaxHeight?: number;
  showFooterDivider?: boolean;
  showHeaderDivider?: boolean;
  component?: React.ReactNode;
  className?: string;
}
export type StepDefinitions = {
  [key in Steps]: StepDefinition;
};

export enum ApprovalChallengeStatus {
  PENDING_APPROVAL = OriginalApprovalStatus.PENDING_APPROVAL,
  APPROVED = OriginalApprovalStatus.APPROVED,
  EXPIRED = OriginalApprovalStatus.EXPIRED,
  DECLINED = OriginalApprovalStatus.DECLINED,
  ERROR = "ERROR",
}
