import {
  ApprovalChallengeStatus,
  StepDefinitions,
  Steps,
} from '../../../stores/premiumUsers2FaEnforcement/ownerApp/types';
import { TextButton } from '@wix/design-system';
import { OwnerAppStore } from '../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import { makeAutoObservable, observable } from 'mobx';
import React, { ReactNode } from 'react';
import { ConfirmDeviceStep } from './ConfirmDeviceStep';
import { ChooseDeviceStep } from './SelectDeviceStep';
import { BackupMethodStep } from './BackupMethodStep';
import { SuccessfulStep } from './SuccessfulStep';
import { DownloadAppIntroStep } from './DownloadAppIntroStep';
import { DownloadAppQrCodeStep } from './DownloadAppQrCodeStep';
import s from '../PremiumUsers2FaEnforcement/modals.scss';

export class UiStore {
  constructor(private store: OwnerAppStore) {
    makeAutoObservable(this, {
      steps: observable.shallow,
    });
  }

  public steps: StepDefinitions = {
    [Steps.DeviceSelectionStep]: {
      modalWidth: 600,
      component: <ChooseDeviceStep />,
      className: `${s.enforcementOwnerAppModal} ${s.hideDividers}`,
    },
    [Steps.ConfirmationStep]: {
      modalHeight: 420,
      modalWidth: 600,
      component:
        this.store.authStatus === ApprovalChallengeStatus.PENDING_APPROVAL ||
        this.store.authStatus === ApprovalChallengeStatus.APPROVED ? (
          <ConfirmDeviceStep.Pending />
        ) : (
          <ConfirmDeviceStep.Error />
        ),
      className: `${s.enforcementOwnerAppModal} ${s.hideDividers}`,
    },
    [Steps.SuccessfulStep]: {
      primaryButtonText: 'wixAppEnforcement.addOwnerApp.wizard.successful.cta',
      primaryButtonOnClick: () =>
        this.store.proceedToPostAuthUrl('Add 2FA over owner app successful'),
      sideActions: (
        <TextButton
          size="small"
          weight="thin"
          underline="always"
          skin={'dark'}
          onClick={this.store.onEnableMoreLoginVerificationMethods}
        >
          {this.translate(
            'wixAppEnforcement.addOwnerApp.wizard.successful.sideAction',
          )}
        </TextButton>
      ) as ReactNode,

      modalHeight: 224,
      modalWidth: 600,
      showFooterDivider: false,
      component: <SuccessfulStep />,
      className: `${s.enforcementOwnerAppModal} ${s.hideDividers}`,
    },
    [Steps.BackupMethodStep]: {
      component: <BackupMethodStep />,
      modalWidth: 546,
      modalHeight: 324,
      showHeaderDivider: true,
      title: 'wixAppEnforcement.addOwnerApp.wizard.backup.title',
      subtitle: 'wixAppEnforcement.addOwnerApp.wizard.backup.subtitle',
      primaryButtonText: 'Continue',
      primaryButtonOnClick: () =>
        new Promise<void>((resolve) => {
          this.store.requestAddBackupMethod();
          resolve();
        }),
      className: `${s.enforcementOwnerAppModal}`,
    },
    [Steps.DownloadAppIntroStep]: {
      modalWidth: 600,
      modalHeight: 405,
      component: <DownloadAppIntroStep />,
      className: `${s.enforcementOwnerAppModal} ${s.hideDividers}`,
    },
    [Steps.DownloadAppQrCodeStep]: {
      modalWidth: 600,
      component: <DownloadAppQrCodeStep />,
      className: `${s.enforcementOwnerAppModal} ${s.hideDividers}`,
    },
  };

  translate(s?: string) {
    return s ? this.store.premiumUsers2FaEnforcementStore.t(s) : s;
  }

  get title() {
    return this.translate(this.steps[this.store.activeStep].title);
  }

  get subtitle() {
    return this.translate(this.steps[this.store.activeStep].subtitle);
  }

  get primaryButtonText() {
    return this.translate(this.steps[this.store.activeStep].primaryButtonText);
  }

  get primaryButtonOnClick() {
    return this.steps[this.store.activeStep].primaryButtonOnClick;
  }

  get secondaryButtonText() {
    return this.translate(
      this.steps[this.store.activeStep].secondaryButtonText,
    );
  }

  get secondaryButtonOnClick() {
    return this.steps[this.store.activeStep].secondaryButtonOnClick;
  }

  get sideActions() {
    return this.steps[this.store.activeStep].sideActions;
  }

  get currentStep() {
    return this.steps[this.store.activeStep].currentStep;
  }

  get showFooterDivider() {
    return this.steps[this.store.activeStep].showFooterDivider;
  }

  get showHeaderDivider() {
    return this.steps[this.store.activeStep].showHeaderDivider;
  }

  get modalHeight() {
    return this.steps[this.store.activeStep].modalHeight;
  }

  get modalWidth() {
    return this.steps[this.store.activeStep].modalWidth;
  }

  get modalMaxHeight() {
    return this.steps[this.store.activeStep].modalMaxHeight;
  }

  get component() {
    return this.steps[this.store.activeStep].component;
  }
  get className() {
    return this.steps[this.store.activeStep].className;
  }
}
