import { Box, Image, Loader, Text } from '@wix/design-system';
import { StatusAlert } from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SocialProviderLoginStore } from '../../stores/socialProviderLogin';
import { AppContextProvider } from '../AppLoader';
import sa from '../SocialAuth/SocialAuth.scss';
import { ThemedText } from '../ThemedComponents';
import sl from './SocialLoginButton.scss';

interface SocialLoginButtonProps<T> {
  socialStore: SocialProviderLoginStore<T>;
  children: React.ReactNode;
  additionalProps?: { [key: string]: string };
  width?: string;
  loaderWidth?: string;
  buttonClassName?: string;
  dataHook?: string;
  inlineShowError?: boolean;
}

interface SocialLoginButtonContext<T> {
  socialStore: SocialProviderLoginStore<T>;
}

const SocialLoginButtonContextProvider = createContext<
  SocialLoginButtonContext<any>
>({} as any);

const _SocialLoginButton = observer(
  <T,>({
    children,
    socialStore,
    additionalProps = {},
    buttonClassName,
    width = '100%',
    loaderWidth = '100%',
    dataHook,
    inlineShowError = true,
  }: SocialLoginButtonProps<T>) => {
    useEffect(() => {
      // Every initial render of the social buttons container we need to trigger the sdks rerender
      if (socialStore.isInitialized) {
        socialStore.initializeSdk(additionalProps);
      }
    }, [socialStore.isInitialized]);
    useEffect(
      () => () => {
        socialStore.clearErrors();
      },
      [],
    );
    const {
      rootStore: {
        displayStore: { isMobile },
      },
    } = useContext(AppContextProvider);
    return (
      <SocialLoginButtonContextProvider.Provider value={{ socialStore }}>
        <Box
          width={isMobile ? width : undefined}
          height={socialStore.hasError && inlineShowError ? '87px' : '37px'}
          direction="vertical"
          gap={1}
          align="center"
          marginBottom={3}
          dataHook={dataHook}
        >
          {socialStore.isLoading && (
            <Box className={sl.loader} width={loaderWidth} align="center">
              <Loader size="small" />
            </Box>
          )}
          <Box
            width="100%"
            className={classNames(buttonClassName, {
              [sl.hiddenButton]: socialStore.isLoading,
              [sa.error]: socialStore.hasError,
            })}
          >
            {children}
          </Box>
          {socialStore.hasError && inlineShowError && <SocialLoginError />}
        </Box>
      </SocialLoginButtonContextProvider.Provider>
    );
  },
);

export const SocialLoginError = observer(() => {
  const {
    rootStore: {
      displayStore: { socialButtonsWidth },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box direction="horizontal" align="center" width={socialButtonsWidth}>
      <ThemedText skin="error" className={sl.statusText} size="small">
        <StatusAlert />
        {t('socialLogin.error.general')}
      </ThemedText>
    </Box>
  );
});

export const SocialLoginIcon = observer(
  <T,>({ children, ...props }: Omit<SocialLoginButtonProps<T>, 'width'>) => {
    return (
      <SocialLoginButton {...props} width="40px" inlineShowError={false}>
        {children}
      </SocialLoginButton>
    );
  },
);

const CustomLoginButton = observer(() => {
  const {
    rootStore: { socialAuthStore, displayStore },
  } = useContext(AppContextProvider);
  const { socialStore } = useContext(SocialLoginButtonContextProvider);
  const { t } = useTranslation();
  if (!displayStore.isVerticalLayout) {
    return <SocialLoginButton.OldCustomLoginButton />;
  }
  return (
    <button
      style={{ width: displayStore.socialButtonsWidth }}
      className={classNames(
        sl.socialButton,
        sl.a11yFocus,
        sl[socialStore.providerId],
        {
          [sl.newDesign]: displayStore.isVerticalLayout,
          [sl.mobile]: displayStore.isMobile,
        },
      )}
      onClick={() => socialStore.onClickCustomLoginButton()}
    >
      <Image
        transparent={true}
        src={socialStore.customButtonLogoUrl}
        className={sl.logo}
        borderRadius="50%"
      />
      <Text
        light
        className={sl.title}
        size={displayStore.isMobile ? 'small' : 'medium'}
      >
        {t('socialLogin.button.text', {
          provider: socialAuthStore.generateProviderName(
            socialStore.providerId,
          ),
        })}
      </Text>
    </button>
  );
});

const OldCustomLoginButton = observer(() => {
  const {
    rootStore: { socialAuthStore, displayStore, experiments },
  } = useContext(AppContextProvider);
  const { socialStore } = useContext(SocialLoginButtonContextProvider);
  const { t } = useTranslation();
  const newGoogleSdk = experiments?.enabled(
    'specs.ident.UseNewGoogleSdkReactLoginApp',
  );

  return (
    <button
      style={{ width: displayStore.socialButtonsWidth }}
      className={classNames(
        sl.old,
        sl.socialButton,
        sl.a11yFocus,
        sl[socialStore.providerId],
        { [sl.newGoogleStyleButtons]: newGoogleSdk },
      )}
      onClick={() => socialStore.onClickCustomLoginButton()}
    >
      <Box
        className={sl.logoContainer}
        verticalAlign="middle"
        direction="horizontal"
        align="center"
      >
        <Image
          alt={socialStore.providerId}
          transparent={true}
          src={socialStore.customButtonLogoUrl}
          className={sl.logo}
          borderRadius="0"
          fit="contain"
        />
      </Box>
      <Text light className={sl.title}>
        {t('socialLogin.button.text', {
          provider: socialAuthStore.generateProviderName(
            socialStore.providerId,
          ),
        })}
      </Text>
    </button>
  );
});

const CustomLoginIcon = observer(() => {
  const { socialStore } = useContext(SocialLoginButtonContextProvider);
  const {
    rootStore: { socialAuthStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  const logo = socialStore.customIconLogoUrl ?? socialStore.customButtonLogoUrl;
  return (
    <button
      className={classNames(
        sl.socialIcon,
        sl[socialStore.providerId],
        sl.a11yFocus,
      )}
      onClick={() => socialStore.onClickCustomLoginButton()}
    >
      <Image
        alt={t('socialLogin.button.text', {
          provider: socialAuthStore.generateProviderName(
            socialStore.providerId,
          ),
        })}
        transparent={true}
        src={logo}
        className={sl.logo}
      />
    </button>
  );
});

export const SocialLoginButton = Object.assign(_SocialLoginButton, {
  OldCustomLoginButton,
  CustomLoginButton,
  CustomLoginIcon,
});
