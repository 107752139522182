import React, { FC, useCallback, useEffect } from 'react';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { ThemedButton } from '../ThemedComponents';
import { Box, Image } from '@wix/design-system';
import ownerApp2FAExpired from '../../assets/images/ownerApp2FAExpired.svg?url';
import { useTranslation } from 'react-i18next';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';
import { loginOwnerApp2FaLoginRequestDeniedPageView } from '@wix/bi-logger-identity-data/v2';

export const OwnerApp2FADeclined: FC = () => {
  const { t } = useTranslation();
  const { store, biLogger } = useTwoFactorAuth();

  const sendDeclinedViewBi = useCallback(async () => {
    await biLogger.report(loginOwnerApp2FaLoginRequestDeniedPageView({}));
  }, [biLogger]);

  useEffect(() => {
    sendDeclinedViewBi();
  }, [sendDeclinedViewBi]);

  return (
    <>
      <DialogHeader>
        <DialogHeader.Title flow='secondaryFlow'>
          {t('ownerAppTwoFactorAuth.declined.title')}
        </DialogHeader.Title>
        <DialogHeader.SubTitleSmall size="medium" weight="thin">
          {t('ownerAppTwoFactorAuth.declined.description')}
        </DialogHeader.SubTitleSmall>
        <Box direction="vertical" margin="58px 0 21px 0" align="center">
          <Image src={ownerApp2FAExpired} transparent width="120px" />
        </Box>
        <ThemedButton
          size="medium"
          skin="dark"
          priority="secondary"
          onClick={() => {
            store.backToLogin();
          }}
        >
          {t('ownerAppTwoFactorAuth.declined.backToLogin')}
        </ThemedButton>
      </DialogHeader>
    </>
  );
};
