import React, { useContext } from 'react';
import { Box, ButtonProps, Loader } from '@wix/design-system';
import { ThemedButton } from '../ThemedComponents';
import Arrow from '../../assets/images/arrow.svg';
import s from './ButtonWithLoader.scss';
import classNames from 'classnames';
import { AppContextProvider } from '../AppLoader';

type ButtonWithLoaderProps = ButtonProps & {
  onMouseDown?: (e: any) => void;
  showLoader?: boolean;
  showArrowOnOver?: boolean;
};

export const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = ({
  showLoader,
  showArrowOnOver,
  ...props
}) => {
  const {
    rootStore: {
      displayStore: { preset },
    },
  } = useContext(AppContextProvider);
  const [isLoading, setIsLoading] = React.useState(false);
  const onClick = React.useCallback(
    (e: any) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      Promise.all([props?.onClick?.(e)]).finally(() => setIsLoading(false));
    },
    [isLoading, props],
  );

  return (
    <Box width={preset.buttonDesign?.fullWidth ? '100%' : 'auto'}>
      <ThemedButton
        size="large"
        {...props}
        className={classNames(
          { [s.arrowAnimation]: showArrowOnOver && !isLoading },
          props.className,
        )}
        suffixIcon={
          <ButtonSuffixIcon
            showLoader={showLoader}
            suffixIcon={props.suffixIcon}
            isLoading={isLoading}
            showArrowOnOver={showArrowOnOver}
            disabled={props.disabled}
          />
        }
        onClick={onClick}
      >
        {isLoading || showLoader ? <Loader size="tiny" /> : props.children}
      </ThemedButton>
    </Box>
  );
};

const ButtonSuffixIcon: React.FC<
  ButtonWithLoaderProps & {
    isLoading: boolean;
  }
> = ({ showArrowOnOver, showLoader, disabled, suffixIcon, isLoading }) => {
  if (disabled) {
    return null;
  }

  if (showArrowOnOver && !isLoading && !showLoader) {
    return (
      <span className={classNames(s.arrowIcon, s.icon)}>
        <Arrow />
      </span>
    );
  }
  return <>{suffixIcon}</>;
};
