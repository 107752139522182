import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { StepperStep, StepperStepType } from '@wix/design-system';
import { VerifyCode } from '../verifyCode';
import {
  ownerApp2FaSetupChoosingABackup,
  ownerApp2FaSetupUserClickedOnChoosingDeviceScreen,
  ownerApp2FaSetupClickOnResendRequest,
  faEnforcementMainScreenDisplaySrc5Evid1879,
} from '@wix/bi-logger-identity-data/v2';

import { PremiumUsers2FaEnforcementStore } from '../premiumUsers2FaEnforcement';
import { Device } from '../../../services/DeviceRegistry';
import { MultiFactorIdentifier } from '../multiFactorAuth';
import {ApprovalChallengeStatus, Steps} from './types';
import { BI_ORIGIN, BI_SCREEN_NAMES } from '../../constants';
import { checkChallengeStatus, multiStateRetry, MultiStateRetryStream } from '@wix/identity-fed-common';
import { EXPERIMENTS } from '../../../utils/constants';
import {EnforcementFlow} from "../types";

export enum EnableState {
  Idle = 0,
  Enabling = 1,
  Enabled = 2,
}

export enum SendNotificationState {
  Idle = 0,
  Notifying = 1,
  Notified = 2,
}

export enum BackupMethods {
  TOTP = 'totp',
  SMS = 'sms',
  EMAIL = 'email',
}

export const BackupMethodIndexToMfaType = {
  0: 'auth app',
  1: 'sms',
  2: 'email',
};

export const BackupMethodsToIndex = {
  [BackupMethods.TOTP]: 0,
  [BackupMethods.SMS]: 1,
  [BackupMethods.EMAIL]: 2,
};

export class OwnerAppStore extends VerifyCode {
  public waitingForBackupMethod: boolean = false;
  public devices: Device[] = [];
  public selectedDevice?: Device;
  public selectedBackupMethod: number = 0;
  public authStatus: ApprovalChallengeStatus =
    ApprovalChallengeStatus.PENDING_APPROVAL;
  public activeStep: Steps;
  public resendErrorMessage: string;
  private verificationId: string;
  private timer: NodeJS.Timer;
  private origin: string;
  public enableState: EnableState = EnableState.Idle;
  public sendNotificationState: SendNotificationState =
    SendNotificationState.Idle;
  private pollingStream: MultiStateRetryStream<ApprovalChallengeStatus>;
  public showNoDevicesError: boolean = false;
  public showResendError: boolean = false;

  constructor(
    public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore,
  ) {
    super(premiumUsers2FaEnforcementStore);
    makeObservable(this, {
      devices: observable,
      selectedDevice: observable,
      authStatus: observable,
      activeStep: observable,
      noBackupMethodsEnabled: computed,
      enableState: observable,
      sendNotificationState: observable,
      selectedBackupMethod: observable,
      resendErrorMessage: observable,
      openModal: action,
      onSelectDevice: action,
      onSelectBackupMethod: action,
      enable: action.bound,
      sendPushToSelectedDevice: action.bound,
      nextStep: action.bound,
      setActiveStep: action.bound,
      init: action.bound,
      updateAuthStatus: action.bound,
      hasConnectedDevices: computed,
      startPostAuthRedirect: action.bound,
      onBackToChooseVerificationMethod: action.bound,
      showNoDevicesError: observable,
      showResendError: observable,
      onChangeDevice: action.bound,
    });
    this.onUseAnotherVerificationMethod =
      this.onUseAnotherVerificationMethod.bind(this);
    this.onEnableMoreLoginVerificationMethods =
      this.onEnableMoreLoginVerificationMethods.bind(this);
    this.reactToAuthStatus();
    this.init()
  }

  public async showWixAppEnforcement() {
    this.devices = await this.fetchUserDevices();
    this.premiumUsers2FaEnforcementStore.setSelectedVerificationMethod();
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      faEnforcementMainScreenDisplaySrc5Evid1879({
        is_owa_registered: this.hasConnectedDevices,
        is_multiple_devices: this.devices.length > 1,
        origin: '2fa_enforcement'
      }),
    )
    if (this.hasNoConnectedDevices) {
      this.premiumUsers2FaEnforcementStore.enforcementFlow = EnforcementFlow.USER_HAS_NO_APP
      this.activeStep = Steps.DownloadAppIntroStep;
    } else {
      this.selectedDevice = this.devices[0];
      if (this.hasOneConnectedDevice) {
        this.activeStep = Steps.ConfirmationStep;
        await this.sendPushToSelectedDevice();
      }
      else {
        this.activeStep = Steps.DeviceSelectionStep
      }
    }
    const postLoginRoutes = this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
    const {navigate} = this.premiumUsers2FaEnforcementStore.navigationStore
    return navigate(postLoginRoutes.WIX_APP_ENFORCEMENT)
  }

  public async init() {
    this.updateAuthStatus(ApprovalChallengeStatus.PENDING_APPROVAL);
    if (this.premiumUsers2FaEnforcementStore.postLoginStore.shouldShowWixAppEnforcement) {
      await this.showWixAppEnforcement();
    } else {
      this.activeStep = Steps.DeviceSelectionStep;
    }
  }

  private reactToAuthStatus() {
    reaction(
      () => this.authStatus,
      async () => {
        if (this.authStatus !== ApprovalChallengeStatus.APPROVED) {
          return;
        }
        try {
          if (this.noBackupMethodsEnabled) {
            await this.premiumUsers2FaEnforcementStore.confirmEmail.onVerifyCode();
            if (this.noBackupMethodsEnabled) {
              this.activeStep = Steps.BackupMethodStep;
              this.waitingForBackupMethod = true;
              return;
            }
          }
          this.activeStep = Steps.SuccessfulStep;
        } catch (e) {}
      },
    );
  }

  public onStepClick = (stepIndex: number) => {
    if (stepIndex === 0) {
      this.activeStep = Steps.DeviceSelectionStep;
    }
  };

  onUseAnotherVerificationMethod() {
    this.waitingForBackupMethod = false;
    this.stopPolling()
    this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
      this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
        .PREMIUM_USERS_2FA_ENFORCEMENT,
    );
  }

  onChangeDevice() {
    this.activeStep = Steps.DeviceSelectionStep;
  }

  onEnableMoreLoginVerificationMethods() {
    this.waitingForBackupMethod = false;
    this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
      this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
        .PREMIUM_USERS_2FA_ENFORCEMENT,
    );
  }

  onBackToChooseVerificationMethod() {
    switch (this.activeStep) {
      case Steps.DeviceSelectionStep:
        this.waitingForBackupMethod = false;
        this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
          this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
            .PREMIUM_USERS_2FA_ENFORCEMENT,
        );
        break;
      case Steps.ConfirmationStep:
      case Steps.BackupMethodStep:
        this.activeStep = Steps.DeviceSelectionStep;
        break;
    }
  }

  public setActiveStep(step: Steps) {
    this.activeStep = step;
  }

  public async enable() {
    this.enableState = EnableState.Enabling;
    await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.OwnerApp.enable(
      this.verificationId,
      this.selectedDevice?.id,
    );
    this.enableState = EnableState.Enabled;
    this.setAsEnabled();
  }

  public startPostAuthRedirect() {
    if (this.enableState === EnableState.Enabled) {
      const timer = setTimeout(() => {
        this.premiumUsers2FaEnforcementStore.postLoginStore.proceedToPostAuthUrl(
          'Add 2FA over owner app successful',
        );
      }, 1000);

      return () => clearTimeout(timer);
    }
  }

  public fetchUserDevices = async () => {
    if (this.devices.length > 0) {
      return this.devices
    }
    this.devices = await this.premiumUsers2FaEnforcementStore.postLoginStore.deviceRegistry.fetchUserDevices(
      this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails.guid,
    );
    this.selectedDevice = this.devices[0];
    return this.devices
  };

  async openModal(asAddOn: boolean) {
    const postLoginStore = this.premiumUsers2FaEnforcementStore.postLoginStore
    const postLoginRoutes = postLoginStore.postLoginRoutes
    const { navigate } = this.premiumUsers2FaEnforcementStore.navigationStore
    await this.fetchUserDevices()
    this.origin = asAddOn
      ? BI_ORIGIN.TWO_FA_ENFORCEMENT_SECOND_METHOD
      : BI_ORIGIN.TWO_FA_ENFORCEMENT;
    await runInAction(async () => {
      await this.init();
      if (postLoginStore.shouldShowWixAppEnforcement) {
        return navigate(postLoginRoutes.WIX_APP_ENFORCEMENT)
      }
      if (asAddOn) {
        return navigate(postLoginRoutes.PREMIUM_USERS_2FA_WIX_OWNER_APP_SECOND_METHOD)
      }
      return navigate(postLoginRoutes.PREMIUM_USERS_2FA_WIX_OWNER_APP)
    });
  }
  onSelectDevice(id?: string | number) {
    this.selectedDevice = this.devices.find((device) => device.id === id);
  }

  onSelectBackupMethod(method: number | string | undefined) {
    if (typeof method === 'number') {
      this.selectedBackupMethod = method;
    }
  }

  requestAddBackupMethod() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      ownerApp2FaSetupChoosingABackup({
        mfaType: BackupMethodIndexToMfaType[this.selectedBackupMethod],
      }),
    );
    switch (this.selectedBackupMethod) {
      case BackupMethodsToIndex[BackupMethods.SMS]:
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.onRequestAddFactor(
          MultiFactorIdentifier.Phone,
        );
        break;
      case BackupMethodsToIndex[BackupMethods.TOTP]:
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.onRequestAddFactor(
          MultiFactorIdentifier.AuthApp,
        );
        break;
      case BackupMethodsToIndex[BackupMethods.EMAIL]:
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.onRequestAddFactor(
          MultiFactorIdentifier.Email,
        );
        break;
    }
  }

  setAsEnabled() {
    this.premiumUsers2FaEnforcementStore.twoFASettings.ownerAppMethod = {};
  }

  async sendPushToSelectedDevice(
    resend?: boolean,
    authStatus?: ApprovalChallengeStatus,
  ) {
    try {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (resend) {
        this.authStatus = ApprovalChallengeStatus.PENDING_APPROVAL;
        this.resendSuccessfullyIndication = true;

        this.premiumUsers2FaEnforcementStore.sendBiEvent(
          ownerApp2FaSetupClickOnResendRequest({
            screenName: this.getScreenName(authStatus!!),
            origin: this.origin,
          }),
        );
      }
      const res =
        await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.sendVerificationCode(
          {
            deviceId: this.selectedDevice?.id,
          },
        );
      this.verificationId = res.data.verificationId;
      if (this.premiumUsers2FaEnforcementStore.myAccountExperiments.enabled(EXPERIMENTS.SHOULD_MULTI_POLLING)) {
        this.authStatus = ApprovalChallengeStatus.PENDING_APPROVAL;
        await this.multiCheckOwnerAuthStatus(this.verificationId)
      }
      await this.checkOwnerAuthStatus(this.verificationId);
    } catch (e) {
      if (resend) {
        this.showResendError = true;
        return (this.resendErrorMessage =
          this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
            'addOwnerApp.wizard.error.resend',
          ));
      }
    }
  }

  getScreenName(authStatus: ApprovalChallengeStatus) {
    switch (authStatus) {
      case ApprovalChallengeStatus.EXPIRED:
        return BI_SCREEN_NAMES.ENFORCEMENT_FAILED_TO_CONFIRM_DEVICE;
      case ApprovalChallengeStatus.DECLINED:
        return BI_SCREEN_NAMES.ENFORCEMENT_LOGIN_REQUEST_WAS_DENIED;
      case ApprovalChallengeStatus.PENDING_APPROVAL:
        return BI_SCREEN_NAMES.APPROVE_DEVICE;
    }
  }

  async multiCheckOwnerAuthStatus(verificationId: string) {
    if (this.pollingStream) {
      return this.pollingStream.add(verificationId)
    }
    this.pollingStream = multiStateRetry<ApprovalChallengeStatus>(verificationId, checkChallengeStatus, ApprovalChallengeStatus.PENDING_APPROVAL)
    return this.pollingStream.promise.then(({status, id}) => {
      this.authStatus = status
      this.verificationId = id
    }, status => {
      this.authStatus = status !== ApprovalChallengeStatus.PENDING_APPROVAL ? status : ApprovalChallengeStatus.EXPIRED
      throw status
    })
  }

  async checkOwnerAuthStatus(verificationId: string) {
    const TIME_OUT_EXPIRED = 60000 * 10;
    const interval = 2000;
    let status: ApprovalChallengeStatus;
    this.updateAuthStatus(ApprovalChallengeStatus.PENDING_APPROVAL);

    this.timer = setInterval(async () => {
      try {
        const result =
          await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.OwnerApp.getStatus(
            verificationId,
          );
        status = result.challenge?.approvalChallenge?.status;
      }
      catch (e) {
        status = ApprovalChallengeStatus.ERROR;
      }
      this.updateAuthStatus(status ?? ApprovalChallengeStatus.PENDING_APPROVAL);
      if (status !== ApprovalChallengeStatus.PENDING_APPROVAL) {
        this.resendSuccessfullyIndication = false;
        clearInterval(this.timer);
      }
    }, interval);

    setTimeout(() => {
      clearInterval(this.timer);
    }, TIME_OUT_EXPIRED);
  }

  public updateAuthStatus(status: ApprovalChallengeStatus) {
    this.authStatus = status;
  }

  async nextStep() {
    switch (this.activeStep) {
      case Steps.DeviceSelectionStep:
        this.sendNotificationState = SendNotificationState.Notifying;
        await this.sendPushToSelectedDevice();
        this.sendNotificationState = SendNotificationState.Notified;
        this.premiumUsers2FaEnforcementStore.sendBiEvent(
          ownerApp2FaSetupUserClickedOnChoosingDeviceScreen({
            chosenDevice: this.selectedDevice?.name,
            origin: this.origin,
          }),
        );
        this.activeStep = Steps.ConfirmationStep;
        break;
      case Steps.BackupMethodStep:
        this.requestAddBackupMethod();
        break;
      case Steps.DownloadAppIntroStep:
        this.activeStep = Steps.DownloadAppQrCodeStep;
        break;
      case Steps.DownloadAppQrCodeStep:
        this.devices = await this.fetchUserDevices();
        if (this.hasNoConnectedDevices) {
          this.showNoDevicesError = true;
        }
        else {
          this.selectedDevice = this.devices[0];
          this.sendNotificationState = SendNotificationState.Notifying;
          await this.sendPushToSelectedDevice();
          this.sendNotificationState = SendNotificationState.Notified;
          this.premiumUsers2FaEnforcementStore.sendBiEvent(
            ownerApp2FaSetupUserClickedOnChoosingDeviceScreen({
              chosenDevice: this.selectedDevice?.name,
              origin: this.origin,
            }),
          );
          this.activeStep = Steps.ConfirmationStep;
        }
        break;
      default:
        break;
    }
  }

  stopPolling() {
    clearInterval(this.timer);
  }

  getStepType(step: Steps) {
    if (this.activeStep === step) {
      return 'normal' as StepperStepType;
    }
    if (this.activeStep > step) {
      return 'completed' as StepperStepType;
    }
    return 'disabled' as StepperStepType;
  }

  get steps(): StepperStep[] {
    const steps = [
      {
        text: this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.choose.title',
        ),
        type: this.getStepType(Steps.DeviceSelectionStep),
      },
      {
        text: this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.confirm.title',
        ),
        type: this.getStepType(Steps.ConfirmationStep),
      },
    ];

    if (this.noBackupMethodsEnabled) {
      steps.push({
        text: this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.backup.title',
        ),
        type: this.getStepType(Steps.BackupMethodStep),
      });
    }

    return steps;
  }

  get primaryButtonText() {
    switch (this.activeStep) {
      case Steps.DeviceSelectionStep:
      case Steps.BackupMethodStep:
        return this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.primary',
        );
      default:
        return undefined;
    }
  }

  get hasConnectedDevices() {
    return this.devices.length > 0;
  }

  get hasOneConnectedDevice() {
    return this.devices.length == 1;
  }

  get hasNoConnectedDevices() {
    return this.devices.length == 0;
  }

  get noBackupMethodsEnabled() {
    return this.premiumUsers2FaEnforcementStore.multiFactorAuth
      .hasNoBackupMethodsEnabled;
  }

  get email() {
    return this.premiumUsers2FaEnforcementStore.loginInfoStore.email.value;
  }

  public onClickLearnMoreLink() {
    // TODO: Do we need to send a BI event here?;
  }
  get learnMoreLink(): string {
    return `https://support.wix.com/${this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.language.locale}/article/secure-your-account-with-2-step-verification`;
  }
}
