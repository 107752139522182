import { Box, Divider } from '@wix/design-system';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { ThemedText } from '../ThemedComponents';
import s from './PoliciesContainer.scss';

interface PoliciesContainerProps {
  dataHook?: string;
  className?: string;
}

const MIN_TOP_MARGIN = 48;
const SHOW_DIVIDER_LIMIT = 810;

const _PoliciesContainer: React.FC<PoliciesContainerProps> = observer(
  ({ dataHook = dataHooks.policiesSection.container, children, className }) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);

    const [useResponsiveClass, setUseResponsiveClass] = useState(true);
    const [onResizeCdId, setOnResizeCdId] = useState('');
    const [topOffset, setTopOffset] = useState(0);

    const onResize = React.useCallback(() => {
      const policiesElement = document?.querySelector(
        '[data-hook="policiesSection.container"]',
      ) as any;
      const dialogElement = document?.querySelector(
        '[class*="fullScreenViewContainer"]',
      ) as any;
      if (!policiesElement || !dialogElement) {
        !useResponsiveClass && setUseResponsiveClass(true);
        return;
      }
      const dialogBottom =
        dialogElement.offsetTop + dialogElement.offsetHeight + MIN_TOP_MARGIN;
      if (dialogBottom > policiesElement.offsetTop) {
        setUseResponsiveClass(false);
        setTopOffset(dialogBottom);
      }
    }, [useResponsiveClass]);

    useEffect(() => {
      onResize();
      setOnResizeCdId(displayStore.registerOnResize(onResize));
      // Unmount
      return () => {
        displayStore.unRegisterOnResize(onResizeCdId);
      };
    }, []);

    return (
      <div
        data-hook={dataHook}
        className={classnames(className, s.policiesContainer, {
          [s.mobilePoliciesContainer]: displayStore.isMobile,
          [s.desktopPoliciesContainer]: !displayStore.isMobile,
          [s.verticalLayout]: displayStore.isVerticalLayout,
          [s.responsiveTop]: useResponsiveClass,
        })}
        style={useResponsiveClass ? {} : { top: `${topOffset}px` }}
      >
        <Box direction="vertical" gap={1}>
          {children}
          <RecaptchaPolicy />
        </Box>
      </div>
    );
  },
);

const Minimal: React.FC<{ dataHook?: string }> = observer(
  ({ dataHook = dataHooks.policiesSection.minimalContent }) => {
    const {
      rootStore: {
        policiesStore,
        displayStore: { preset },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    return (
      <ThemedText
        size="tiny"
        secondary
        className={s.minimalContainer}
        dataHook={dataHook}
      >
        <PolicyLink
          url={preset.policies?.termsOfUseUrl ?? policiesStore.termsOfUseUrl}
        >
          {t('policies_section.terms_of_use')}
        </PolicyLink>
        <PolicyLink
          url={
            preset.policies?.privacyPolicyUrl ?? policiesStore.privacyPolicyUrl
          }
        >
          {t('policies_section.privacy_policy')}
        </PolicyLink>
      </ThemedText>
    );
  },
);

const Expanded: React.FC<PoliciesContainerProps> = observer(
  ({ dataHook = dataHooks.policiesSection.expandedContent, className }) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);
    return (
      <>
        <Box direction="vertical" gap="10px" dataHook={dataHook}>
          <ThemedText secondary size="tiny">
            <WixExpanded />
          </ThemedText>
        </Box>
        {displayStore.isMobile &&
          displayStore.screenInnerWidth <= SHOW_DIVIDER_LIMIT && <Divider />}
      </>
    );
  },
);

const WixExpanded: React.FC = observer(() => {
  const {
    rootStore: {
      policiesStore,
      displayStore: { preset },
      navigationStore: { getPublicAbsoluteUrl },
    },
  } = useContext(AppContextProvider);
  return (
    <Trans i18nKey="policies_section.signup_content">
      * By signing up, you agree to our
      <PolicyLink
        url={getPublicAbsoluteUrl(
          preset.policies?.termsOfUseUrl ?? policiesStore.termsOfUseUrl,
        )}
      >
        terms-of-use
      </PolicyLink>
      and acknowledge you’ve read our
      <PolicyLink
        url={getPublicAbsoluteUrl(
          preset.policies?.privacyPolicyUrl ?? policiesStore.privacyPolicyUrl,
        )}
      >
        privacy-policy
      </PolicyLink>
    </Trans>
  );
});

const RecaptchaPolicy: React.FC = observer(() => {
  const {
    rootStore: { policiesStore },
  } = useContext(AppContextProvider);
  return (
    <ThemedText
      dataHook={dataHooks.policiesSection.expandedContent}
      secondary
      size="tiny"
    >
      <Trans i18nKey="policies_section.reCaptcha">
        This site is protected by reCAPTCHA Enterprise. Google's
        <PolicyLink url={policiesStore.captchaPrivacyPolicyUrl}>
          privacy-policy
        </PolicyLink>
        and
        <PolicyLink url={policiesStore.captchaTermsOfUseUrl}>
          terms-of-use
        </PolicyLink>
        apply.
      </Trans>
    </ThemedText>
  );
});

const PolicyLink: React.FC<{ url: string }> = ({ children, url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className={s.policiesLink}>
      {children}
    </a>
  );
};

export const PoliciesContainer = Object.assign(_PoliciesContainer, {
  Expanded,
  Minimal,
});
