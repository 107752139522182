import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Button,
  Heading,
  Loader,
  RadioGroup,
  Text,
  TextButton,
  Image,
} from '@wix/design-system';
import {useTranslation} from 'react-i18next';
import {AppContextProvider} from '../../AppLoader';
import {dataHooks} from '../../../dataHooks';
import wixAppDevice from '../../../assets/images/wixAppDeviceNew.svg?url';
import {SendNotificationState} from '../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import {HelpCircleTooltip} from "./HelpCircleTooltip";

export const ChooseDeviceStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: {ownerAppStore: store},
      },
    },
  } = useContext(AppContextProvider);
  const {t} = useTranslation();
  return (
    <>
      <Box width={600}>
        <Box width={576} marginLeft={4} >
          <Box
            dataHook="leftSide"
            direction="vertical"
            width={278}
            padding="24px 0"
          >
            <Box width={252} gap={7} direction="vertical">
              <Box direction="vertical" gap={1} width={220}>
                <Heading dataHook="header title" size="medium">
                  {t('wixAppEnforcement.addOwnerApp.wizard.select.title')}
                </Heading>
                <Box width={252}>
                  <Text size="medium" weight="thin" secondary>
                    {t('wixAppEnforcement.addOwnerApp.wizard.select.subtitle')}
                  </Text>
                </Box>
              </Box>
              <Box direction="vertical" gap={2}>
                <Text size="medium" weight="normal">
                  {t('wixAppEnforcement.addOwnerApp.wizard.select.message')}
                </Text>
                <Box direction="vertical" gap="23px" flexGrow={1} width={280}>
                  <Box maxHeight={138} overflowY="auto">
                    <RadioGroup
                      dataHook={dataHooks.ownerAppModal.options}
                      value={store.selectedDevice?.id}
                      onChange={(id?: string | number) =>
                        store.onSelectDevice(id)
                      }
                      spacing="12px"
                    >
                      {store.devices.map(({id, name}) => (
                        <RadioGroup.Radio key={id} value={id}>
                          <Text
                            weight="thin"
                            secondary={store.selectedDevice?.id !== id}
                            color="dark"
                          >
                            {name}
                          </Text>
                        </RadioGroup.Radio>
                      ))}
                    </RadioGroup>
                  </Box>
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        onClick={store.nextStep}
                      >
                        {store.sendNotificationState ===
                        SendNotificationState.Notifying ? (
                            <Loader size="tiny"/>
                          ) :
                          t(
                            'wixAppEnforcement.addOwnerApp.wizard.select.button',
                          )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box direction={'vertical'} alignContent={'flex-end'} marginTop={3}>
                  <TextButton
                    size="small"
                    weight="thin"
                    underline="always"
                    skin="dark"
                    onClick={store.onUseAnotherVerificationMethod}
                  >
                    {t(
                      'wixAppEnforcement.addOwnerApp.wizard.confirm.another.method',
                    )}
                  </TextButton>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width={300}
            backgroundColor="#E7F0FF"
            align="center"
            direction="vertical"
          >
            <Box width="100%" align="right" marginTop={2} marginRight={2}>
              <HelpCircleTooltip/>
            </Box>
            <Box marginTop="auto">
              <Image src={wixAppDevice} transparent={true}/>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});
