import React, {FC, useContext, useEffect} from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Heading,
  Text,
  TextButton,
  Image, InfoIcon,
} from '@wix/design-system';
import { useTranslation } from 'react-i18next';
import { AppContextProvider } from '../../AppLoader';
import { ResendVerificationCode } from '../ResendVerificationCode';
import wixAppDevice from '../../../assets/images/wixAppDeviceNew.svg?url';
import wixAppDeviceError from '../../../assets/images/wixAppDeviceErrorNew.svg?url';
import {HelpCircleTooltip} from "./HelpCircleTooltip";
import {SendErrorSectionHelper} from "./SendErrorSectionHelper";
import {EnforcementFlow} from "../../../stores/premiumUsers2FaEnforcement/types";
import {UiStore} from "./ConfirmDeviceStepUIStore";


const Pending: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  useEffect(() => {
    return () => {
      store.stopPolling();
    };
  }, [store]);
  const { t } = useTranslation();
  const noAppFlow = store.premiumUsers2FaEnforcementStore.enforcementFlow === EnforcementFlow.USER_HAS_NO_APP
  return (
    <>
      <Box
        dataHook="customModalContent"
        width={576}
        height={403}
        paddingLeft={4}
      >
        {store.showResendError && <SendErrorSectionHelper />}
        <Box width={576} height={420}>
          <Box
            dataHook="left side content"
            width={276}
            padding="24px 0px"
            gap={4}
            direction="vertical"
          >
            <Box
              dataHook="top half"
              width={252}
              gap={7}
              direction="vertical"
            >
              <Box dataHook="header" gap="90px" direction="vertical">
                <Box dataHook="header again" gap="6px" direction="vertical">
                  <Box width={noAppFlow? 245 : 220}>
                    <Heading dataHook="header title" size="medium">
                      {t(`wixAppEnforcement.addOwnerApp.wizard.confirm.${noAppFlow ? 'noApp.': ''}title`)}
                    </Heading>
                  </Box>
                  {!noAppFlow && (
                  <Box
                    dataHook="header body"
                    direction="vertical"
                    gap={4}
                  >
                    <Box
                      dataHook="header body again"
                      direction="vertical"
                      gap="6px"
                    >
                      <Box
                        dataHook="header body again again"
                        direction="vertical"
                      >
                        <Text size="medium" weight="thin" secondary>
                          {
                            !noAppFlow
                              && t('wixAppEnforcement.addOwnerApp.wizard.confirm.subtitle')
                          }
                        </Text>
                      </Box>
                    </Box>
                  </Box>)}
                </Box>
              </Box>
              <Box dataHook="check your iphone" gap={1} direction="vertical">
                <Text size="medium" weight="normal">
                  {t(
                    'wixAppEnforcement.addOwnerApp.wizard.confirm.message.check.phone',
                    { selectedDevice: store.selectedDevice?.name },
                  )}
                </Text>
                <Text size="medium" weight="thin" secondary>
                  {t(
                    'wixAppEnforcement.addOwnerApp.wizard.confirm.message.to.continue',
                  )}
                </Text>
                {store.devices.length > 1 && (
                <TextButton
                  size="small"
                  weight="thin"
                  underline="none"
                  onClick={store.onChangeDevice}
                >
                  {t('wixAppEnforcement.addOwnerApp.wizard.confirm.change.device')}
                </TextButton>
                )}
              </Box>
            </Box>
            <Box
              dataHook="bottom half"
              width={252}
              height={120}
              gap="6px"
              direction="vertical"
            >
              <Box
                dataHook="top of bottom half"
                height={42}
                gap={1}
                direction="vertical"
              >
                <Box dataHook={"didn't get the notification"} gap="4px">
                    <Text size="small" weight="thin">
                      {t(
                        'wixAppEnforcement.addOwnerApp.wizard.confirm.message.resend',
                      )}
                    </Text>
                  <InfoIcon
                    size={'small'}
                    content={t(
                      'wixAppEnforcement.addOwnerApp.wizard.confirm.info.tooltip',
                    )}
                  />
                </Box>
                <ResendVerificationCode
                  sendConfirmationCode={() =>
                    store.sendPushToSelectedDevice(true)
                  }
                  resendSuccessfullyIndication={
                    store.resendSuccessfullyIndication
                  }
                  resendThrottleTimerValue={store.resendThrottleTimerValue}
                  resendButtonTranslationKey="wixAppEnforcement.addOwnerApp.wizard.confirm.resend-link"
                  resendIndicationTranslationKey="wixAppEnforcement.addOwnerApp.wizard.resend-indication"
                  resendButtonSize="small"
                  resendButtonWeight="normal"
                />
              </Box>
            </Box>
            <TextButton
              size="small"
              weight="thin"
              underline="always"
              skin="dark"
              onClick={store.onUseAnotherVerificationMethod}
            >
              {t('wixAppEnforcement.addOwnerApp.wizard.confirm.another.method')}
            </TextButton>
          </Box>
          <Box
            width={300}
            backgroundColor="#E7F0FF"
            align="center"
            direction="vertical"
          >
            <Box width="100%" align="right" marginTop={2} marginRight={2}>
              <HelpCircleTooltip />
            </Box>
            <Box marginTop="auto">
              <Image src={wixAppDevice} transparent={true} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

const Error: FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const uiStore = new UiStore(store);
  const { t } = useTranslation();
  return (
    <Box dataHook="customModalContent" width={576} height={403} paddingLeft={4}>
      {store.showResendError && <SendErrorSectionHelper />}
      <Box width={576} height={420}>
        <Box
          dataHook="left side content"
          width={276}
          padding="24px 0px"
          gap={4}
          direction="vertical"
          justify-content="space-between"
        >
          <Box dataHook="top half" width={276} gap="41px" direction="vertical">
            <Box dataHook="header" gap="90px" direction="vertical">
              <Box dataHook="header again" gap="6px" direction="vertical" width={252}>
                <Heading dataHook="header title" size="medium">{uiStore.title}</Heading>
                <Box
                  dataHook="header body"
                  direction="vertical"
                  gap={4}
                >
                  <Text size="medium" weight="thin" secondary>{uiStore.subtitle}</Text>
                  {uiStore.isError && (
                    <Text size="medium" weight="thin" secondary>
                      {t('wixAppEnforcement.addOwnerApp.wizard.confirm.error.message2')}
                    </Text>
                  )}
                  <ResendVerificationCode
                    sendConfirmationCode={() =>
                      store.sendPushToSelectedDevice(true)
                    }
                    resendSuccessfullyIndication={
                      store.resendSuccessfullyIndication
                    }
                    resendThrottleTimerValue={store.resendThrottleTimerValue}
                    resendButtonTranslationKey="wixAppEnforcement.addOwnerApp.wizard.confirm.resend-link"
                    resendIndicationTranslationKey="post.login.oa.2fa.code.resend.indication"
                    resendButtonSize="small"
                    resendButtonWeight="normal"
                    showAsButton={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <TextButton
            size="small"
            weight="thin"
            underline="always"
            skin="dark"
            onClick={store.onUseAnotherVerificationMethod}
          >
            {t('wixAppEnforcement.addOwnerApp.wizard.confirm.another.method')}
          </TextButton>
        </Box>
        <Box
          width={300}
          backgroundColor="#E7F0FF"
          align="center"
          direction="vertical"
        >
          <Box width="100%" align="right" marginTop={2} marginRight={2}>
            <HelpCircleTooltip />
          </Box>
          <Box marginTop="auto">
            <Image src={wixAppDeviceError} transparent={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export const ConfirmDeviceStep = { Pending, Error };
